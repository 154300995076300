import {useParams, Redirect, Link} from "react-router-dom";
import './RecycleFunStageAnswer.scss';
import back from './back.png';
import Game from "../Game";
import {ReactComponent as Tick} from "./tick.svg";
import {ReactComponent as Cross} from "./cross.svg";

function RecycleFunStageAnswer({stages, answers}) {
  const {stage: stageNumber} = useParams();
  const stage = !isNaN(stageNumber) && stageNumber > 0 && stageNumber <= stages.length ? stages[stageNumber - 1] : null;

  if (stage) {

    let allAnswered = true;
    for (let i = 0 ; i < stage.questions.length ; ++i) {
      const k = `s${stageNumber}q${i+1}`;
      if (answers[k] === undefined || answers[k].length === 0) {
        allAnswered = false;
        break;
      }
    }

    if (allAnswered) {
      return <Game className={"RecycleFunStageAnswer"} headerContent={<Link to="/recycle-hero" className={"back-to-main"}>
        <div className={"icon"}><img src={back} alt={""} /></div>
        <div className={'label'}>All Stages</div>
      </Link>}>

        <div className={"questions"}>
          {stage.questions.map((q, i) => {
            const questionKey = `s${stageNumber}q${i+1}`;
            const userAnswers = answers[questionKey] ?? [];
            return <div className={"question"} key={i}>
              <div className={"question-number"}>{i+1}</div>
              <div className={"question-body"}>
                <div className={"question-name"}>{q.name}</div>
                <div className={"answers"}>
                  {['Paper', 'Metal', 'Plastic', 'Glass', 'Not Recyclable'].map((k, i) => {

                    const correctAnswer = q.answer === k || (q.answer.indexOf && q.answer.indexOf(k) >= 0);
                    const userAnswer = userAnswers.indexOf(k) >= 0;


                    return <div key={i} className={
                      "option" +
                      (correctAnswer ? ' correct-answer' : '') +
                      (userAnswer ? ' user-answer' : '')
                    }>
                      <div className={"icon"}>
                        {userAnswer && correctAnswer && <Tick />}
                        {!userAnswer && correctAnswer && <Cross />}
                      </div>
                      {k}
                    </div>
                  })}
                </div>
              </div>
            </div>
          })}
        </div>
      </Game>;

    } else {
      return <Redirect to={"/recycle-hero/stages/" + stageNumber} />
    }
  } else {
    return <Redirect to={"/recycle-hero"} />;
  }

}

export default RecycleFunStageAnswer;
