import './RecycleFunStageSelector.scss';
import {Link} from "react-router-dom";
import Game from "../Game";
import logo from './recycle-hero-logo.png';

function RecycleFunStageSelector({stages}) {
  return <Game className={"RecycleFunStageSelector"}>
    <div className={"logo"}><img src={logo} alt={"Logo"} /></div>
    <div className={"stages"}>
      {stages.map((st, i) => <Link to={"/recycle-hero/stages/" + (i+1)} className={"stage"} key={i}>
        {st.name}
      </Link>)}
    </div>
  </Game>;
}

export default RecycleFunStageSelector;
