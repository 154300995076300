import './Game.scss';
import TopBar from "../components/TopBar";

function Game({children, headerContent = null, className = ''}) {
  return <div className={"Game " + className}>
    <TopBar>
      {headerContent ? headerContent : <div />}
    </TopBar>
    <div className={"content"}>
      {children}
    </div>
  </div>
}

export default Game;