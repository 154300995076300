/**
 * 1. Go to Firebase console
 * 2. Create or find the project
 * 3. Create a Web Project find the web project
 * 4. Copy the the firebase config and paste it here. JUST the firebaseConfig object.
 */
const firebaseConfig = {
  apiKey: "AIzaSyCVrO670DN4z_YDRzuKafQCIy6vzgdXATk",
  authDomain: "enviro-fcfbe.firebaseapp.com",
  databaseURL: "https://enviro-fcfbe-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "enviro-fcfbe",
  storageBucket: "enviro-fcfbe.appspot.com",
  messagingSenderId: "756743423429",
  appId: "1:756743423429:web:a4cfd7c3d295b97e5e37ac",
  measurementId: "G-7G7T0YGNS2"
};

export default firebaseConfig;