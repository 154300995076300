import {NavLink} from "react-router-dom";
import './RecycleFunStageSidebar.scss';

function RecycleFunStageSidebar({stage, stageNumber, answers}) {

  return <div className={"RecycleFunStageSidebar"}>
    <div className={"question-indexes"}>
      {stage.questions.map((question, i) => {
        const ansK = `s${stageNumber}q${i+1}`;
        return <NavLink
          to={"/recycle-hero/stages/" + stageNumber + "/" + (i+1)}
          key={i}
          className={"question-index" + (answers[ansK] !== undefined && answers[ansK].length > 0 ? ' answered' : '')}>
          <div className={"inner"}>{i+1}</div>
        </NavLink>
      })}
    </div>
  </div>
}

export default RecycleFunStageSidebar;
