import {onValue, push, ref, set} from "firebase/database";
import {db} from "./database";
import { uniqueNamesGenerator, adjectives, animals } from 'unique-names-generator';

const userKey = 'enviro-user-id';
let user = null;
let userId = window.localStorage.getItem(userKey);

export const getUser = () => {

  return new Promise(resolve => {
    const createUser = () => {
      const newUserRef = push(ref(db, 'users'));
      window.localStorage.setItem(userKey, userId = newUserRef.key);
      const data = {
        name: uniqueNamesGenerator({
          dictionaries: [adjectives, animals],
          separator: ' ',
          style: 'capital'
        }),
        createdAt: (new Date()).getTime(),
        updatedAt: (new Date()).getTime(),
      };
      set(newUserRef, data).then(() => {
        resolve(data);
      });
    };

    if (user) {
      resolve(user);
    } else {
      if (userId) {
        onValue(ref(db, 'users/' + userId), snapshot => {
          const data = snapshot.val();
          if (data) {
            resolve(user = data);
          } else {
            createUser();
          }
        }, {
          onlyOnce: true
        });
      } else {
        createUser();
      }
    }
  });
};

export const updateUser = data => {
  return new Promise(resolve => {
    if (userId) {
      set(ref(db, 'users/' + userId), {...data, ...{updatedAt: (new Date()).getTime()}}).then(() => {
        resolve(data);
      });
    }
  });
};

