import './RecycleFunStage.scss';
import {ReactComponent as CheckboxEmpty} from './3325042_square_icon.svg';
import {ReactComponent as Checkbox} from './2561354_check_square_icon.svg';
import './RecycleFunStageQuestion.scss';

const NOT_RECYCLABLE = 'Not Recyclable';

function RecycleFunStageQuestion({stageIndex, questionIndex, question, answer, onAnswer}) {

  const key = `s${stageIndex}q${questionIndex}`;

  const toggleAnswer = ans => {
    const idx = answer.indexOf(ans);
    if (idx < 0) {
      answer.push(ans);
    } else {
      answer.splice(idx, 1);
    }

    const notRecyclableIdx = answer.indexOf(NOT_RECYCLABLE);
    if (notRecyclableIdx >= 0) {
      answer.splice(notRecyclableIdx, 1);
    }
    onAnswer(key, [...answer]);
  };

  const handleNotRecyclable = () => {
    const idx = answer.indexOf(NOT_RECYCLABLE);
    if (idx < 0) {
      onAnswer(key, [NOT_RECYCLABLE]);
    } else {
      onAnswer(key, []);
    }
  }

  return <div className={"RecycleFunStageQuestion"}>
    <div className={"center"}>
      {/*<div className={"title"}>Question {questionIndex}</div>*/}
      {/*<div className={"name"}>{question.name}</div>*/}
      <div className={"options"}>
        <div className={"options-row"}>
          {['Paper', 'Metal'].map((t, i) => <button
            className={"btn option option-" + t + (answer.indexOf(t) >= 0 ? ' active' : '')}
            key={i}
            onClick={() => toggleAnswer(t)}>
                  <span className={"icon"}>
                    {answer.indexOf(t) >= 0 ? <Checkbox /> : <CheckboxEmpty />}
                  </span>
            <span className={"label"}>{t}</span>
          </button>)}
        </div>
        <div className={"options-row"}>
          {['Plastic', 'Glass'].map((t, i) => <button
            className={"btn option option-" + t + (answer.indexOf(t) >= 0 ? ' active' : '')}
            key={i}
            onClick={() => toggleAnswer(t)}>
                  <span className={"icon"}>
                    {answer.indexOf(t) >= 0 ? <Checkbox /> : <CheckboxEmpty />}
                  </span>
            <span className={"label"}>{t}</span>
          </button>)}
        </div>
      </div>
      <div className={"options full"}>
        <button className={"btn option option-" + NOT_RECYCLABLE.replace(/\s/g, '-') + (answer.indexOf(NOT_RECYCLABLE) >= 0 ? ' active' : '')} onClick={handleNotRecyclable}>
          <span className={"icon"}>
            {answer.indexOf(NOT_RECYCLABLE) >= 0 ? <Checkbox /> : <CheckboxEmpty />}
          </span>
          <span className={"label"}>Not Recyclable</span>
        </button>
      </div>
    </div>
  </div>;

}

export default RecycleFunStageQuestion;
