import face from './face.png';
import {useContext} from "react";
import UserContext from "../contexts/UserContext";
import './Profile.scss';
function Profile() {
  const {user} = useContext(UserContext);
  return <div className={"Profile"}>
    <div className={"icon"}>
      <img src={face} alt={''} />
    </div>
    <div className={"name"}>
      {user.name}
    </div>
  </div>
}
export default Profile;