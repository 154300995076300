import Profile from "./Profile";
import './TopBar.scss';

function TopBar({children}) {
  return <div className={"TopBar"}>
    {children}
    <Profile />
  </div>
}

export default TopBar;