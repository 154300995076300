import {useEffect, useState} from "react";
import {setTitle} from "../util";
import config from './recycle-fun.json';
import './RecycleFun.scss';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import RecycleFunStageSelector from "./components/RecycleFunStageSelector";
import RecycleFunStage from "./components/RecycleFunStage";
import RecycleFunStageAnswer from "./components/RecycleFunStageAnswer";

const ANSWER_KEY = 'enviro-recycle-fun-answers';
const EVENT_KEY = 'enviro-recycle-fun-event_id';

const localStorageAnswersStr = window.localStorage.getItem(ANSWER_KEY);
const localStorageEvent = window.localStorage.getItem(EVENT_KEY);

// Reset occurred when the date is different
if (config.event_date !== localStorageEvent) {
  window.localStorage.removeItem(ANSWER_KEY);
  window.localStorage.setItem(EVENT_KEY, config.event_date);
}

let localStorageAnswers = null;
if (localStorageAnswersStr) {
  localStorageAnswers = JSON.parse(localStorageAnswersStr);
  if (typeof localStorageAnswers !== 'object' || Array.isArray(localStorageAnswers) || localStorageAnswers === null) {
    localStorageAnswers = null;
  }
}

function RecycleFun() {

  const [answers, setAnswers] = useState(localStorageAnswers || {});

  const handleAnswers = a => {
    window.localStorage.setItem(ANSWER_KEY, JSON.stringify(a));
    setAnswers(a);
  };

  useEffect(() => {
    setTitle('Recycle Hero');
  }, []);

  return <Router>
    <Switch>
      <Route path={"/recycle-hero/stages/:stage/answers"}>
        <RecycleFunStageAnswer stages={config.stages} answers={answers} />
      </Route>
      <Route path={"/recycle-hero/stages/:stage/:questionIndex"}>
        <RecycleFunStage stages={config.stages} answers={answers} onAnswers={handleAnswers} />
      </Route>
      <Route path={"/recycle-hero/stages/:stage"}>
        <RecycleFunStage stages={config.stages} />
      </Route>
      <Route path={"/recycle-hero/"}>
        <RecycleFunStageSelector stages={config.stages} />
      </Route>
    </Switch>
  </Router>;
}

export default RecycleFun;
