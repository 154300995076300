import './App.scss';
import Clicker from "./games/Clicker";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {useEffect, useState} from "react";
import Home from "./Home";
import {UserProvider} from "./contexts/UserContext";
import {getUser, updateUser} from "./user";
import RecycleFun from "./games/RecycleFun";

function App() {

  const [user, setUser] = useState(null);
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    getUser().then(user => {
      setUser(user);
      setInitialised(true);
    });
  }, []);

  useEffect(() => {
    if (user) updateUser(user);
  }, [user]);

  const handleLogout = () => {

  };

  const userValue = {user, setUser, onLogout: handleLogout};

  return <UserProvider value={userValue}>
    {initialised ? <Router>
      <Switch>
        <Route path={"/recycle-hero"}>
          <RecycleFun />
        </Route>
        <Route path={"/clicker"}>
          <Clicker />
        </Route>
        <Route path={"/"}>
          <Home />
        </Route>
      </Switch>
    </Router> : <div className={"app-loading"}>Loading...</div>}
  </UserProvider>;
}

export default App;
