import {useEffect} from "react";
import {setTitle} from "./util";

function Home() {
  useEffect(() => {
    setTitle();
  }, []);
  return <div style={{margin: 16}}>
    Where you can make a difference to the environment ;)
  </div>;
}

export default Home;