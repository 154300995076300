import {useContext, useEffect, useState} from "react";
import {setTitle} from "../util";
import './Clicker.scss';
import UserContext from "../contexts/UserContext";
import {db} from "../database";
import {set, ref, increment} from "firebase/database";
import bolt from './bolt.png';
import logo from './clicker-logo.png';
import logoSlogan from './clicker-logo-slogan.png';
import Game from "./Game";

const isTouchDevice = () => (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));

const clickEvent = isTouchDevice() ? 'touchstart' : 'mousedown';

function Clicker() {

  const [clicks, setClicks] = useState(0);
  const {user, setUser} = useContext(UserContext);
  const delta = 100;
  const [elapsed, setElapsed] = useState(0);
  const [accumulated, setAccumulated] = useState(0);

  useEffect(() => {
    setTitle('Clicker');
    const clickAmount = window.localStorage.getItem('enviro-clicker-clicks') || 0;
    setClicks(clickAmount * 1);
  }, []);

  useEffect(() => {
    window.localStorage.setItem('enviro-clicker-clicks', clicks);
  }, [clicks]);

  let speed = 0;
  if (elapsed > 0) {
    speed = Math.round((accumulated / elapsed) * 100) / 100;
  }

  useEffect(() => {
    setTimeout(() => {
      setElapsed(elapsed + 1);
    }, 1000);
  }, [elapsed]);

  useEffect(() => {
    // Listen to clicks
    const handleClick = () => {
      setClicks(clicks + 1);
      setAccumulated(accumulated + 1);
    };

    window.addEventListener(clickEvent, handleClick);
    window.addEventListener('keydown', handleClick);
    return () => {
      window.removeEventListener(clickEvent, handleClick);
      window.removeEventListener('keydown', handleClick);
    };
  }, [accumulated, setUser, clicks]);

  useEffect(() => {
    if (user && clicks > 0 && clicks % delta === 0) {
      setUser({...user, ...{clicks}});
      set(ref(db, 'clicker'), {
        clicks: increment(delta)
      });
    }
  }, [setUser, clicks]); // do not listen to user changes to prevent infinity loop

  const handleReset = e => {
    if (window.confirm('Reset clicks? This will only reset your own clicks.')) {
      setAccumulated(0);
      setClicks(0);
      setElapsed(0);
      setUser({...user, ...{clicks: 0}});
    }
  }

  return <Game className={"Clicker"} headerContent={
    <button className={"reset"} onMouseDown={e => e.stopPropagation()} onClick={handleReset}>[Reset Clicks]</button>
  }>
    <div className={"clicker-wrapper"}>
      <div className={"logo"}>
        <img src={logo} alt={"Logo"} className={"logo-logo"} />
        <img src={logoSlogan} alt={"Logo Slogan"} className={"logo-slogan"} />
      </div>
      <div className={"clicker-game-board"}>
        {accumulated === 0 ?
          <div className={"info"}>
            <div className={"clicks"}>
              START
            </div>
            <div className={"guide"}>
              Tap anywhere on the screen to increase click counts
            </div>
          </div> : <>
            <div className={"clicks"}>
              {clicks}
            </div>
            <div className={"label"}>
              Clicks Contributed!
            </div>
            <div className={"speed"}>
              <div className={"bolt"}><img src={bolt} alt="" /></div>
              <div className={"txt"}>{speed} cpm</div>
            </div>
          </>}
      </div>
    </div>

  </Game>
}

export default Clicker;