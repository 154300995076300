import {useParams, Redirect, Link} from "react-router-dom";
import './RecycleFunStage.scss';
import back from './back.png';
import Game from "../Game";
import RecycleFunStageQuestion from "./RecycleFunStageQuestion";
import RecycleFunStageSidebar from "./RecycleFunStageSidebar";

function RecycleFunStage({stages, answers, onAnswers = () => {}}) {
  const {stage: stageNumber, questionIndex} = useParams();
  const stage = !isNaN(stageNumber) && stageNumber > 0 && stageNumber <= stages.length ? stages[stageNumber - 1] : null;
  const question = stage && questionIndex > 0 && questionIndex <= stage.questions.length ? stage.questions[questionIndex - 1] : null;

  const handleAnswer = (key, ans) => {
    answers[key] = ans;
    onAnswers({...answers});
  };

  if (stage) {
    if (question) {

      let allAnswered = true;
      for (let i = 0 ; i < stage.questions.length ; ++i) {
        const k = `s${stageNumber}q${i+1}`;
        if (answers[k] === undefined || answers[k].length === 0) {
          allAnswered = false;
          break;
        }
      }

      return <Game className={"RecycleFunStage stage-" +stageNumber} headerContent={<Link to="/recycle-hero" className={"back-to-main"}>
        <div className={"icon"}><img src={back} alt={""} /></div>
        <div className={'label'}>All Stages</div>
      </Link>}>

        <div className={"game-area"}>
          <RecycleFunStageSidebar
            stage={stage}
            stageNumber={stageNumber}
            answers={answers}
          />
          <div className={"main"}>
            <div className={"container"}>

              {!allAnswered ? <button className={"btn btn-answer"} disabled={true}>Check Answer</button> :
                <Link to={"/recycle-hero/stages/" + stageNumber + "/answers"} className={"btn btn-answer"}>Check Answer</Link>}

              <RecycleFunStageQuestion question={question}
                                       stageIndex={stageNumber}
                                       questionIndex={questionIndex}
                                       answer={answers[`s${stageNumber}q${questionIndex}`] || []}
                                       onAnswer={handleAnswer}
              />
              <div className={"actions"}>
                {questionIndex * 1 === 1 ? <button className={"btn btn-previous"} disabled={true}>Previous</button> :
                  <Link to={"/recycle-hero/stages/" + stageNumber + "/" + Math.max(0, questionIndex * 1 - 1)} className={"btn btn-previous"} >Previous</Link>}
                {questionIndex * 1 === stage.questions.length ? <button className={"btn btn-next"} disabled={true}>Next</button> :
                  <Link to={"/recycle-hero/stages/" + stageNumber + "/" + Math.min(stage.questions.length, questionIndex * 1 + 1)} className={"btn btn-next"}>Next</Link>}
              </div>
            </div>
          </div>
        </div>
      </Game>;
    } else {
      return <Redirect to={"/recycle-hero/stages/" + stageNumber + "/1"} />;
    }
  } else {
    return <Redirect to={"/recycle-hero"} />;
  }

}

export default RecycleFunStage;
